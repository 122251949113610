import React from 'react';
import {File as FWFile} from 'FW/Models/File';
import InlineEditableElement from 'Components/InlineEditableElement';
import {Helper} from '../../../Helpers';

class CarouselSlide extends React.Component {

  constructor() {
    super(...arguments);
    this.slideWidth = 0;
    this.slideHeight = 0;
    this.mouseLeave = this.mouseLeave.bind(this);
    this.mouseOver = this.mouseOver.bind(this);
    this.slideChanged = this.slideChanged.bind(this);
    this.state = {
      slideIndex: 0,
      id: Math.round(Math.random() * 100000)
    };
  }

  componentDidMount(){
    let toBeAnimatedSlide = $('#animationContainer-' + this.props.section.get('id') + '-' + this.props.slideIndex);
    if(this.props.slideIndex !== 0)
      toBeAnimatedSlide.css('display', 'none');
  }

  mouseOver() {
    let toBeAnimated = $('#animationContainer-' + this.props.section.get('id') + '-' + this.props.slideIndex).find('.hoverAnimated');
    toBeAnimated.addClass(this.props.section.getAnimationClass('hover'));
  }

  mouseLeave() {
    let toBeAnimated = $('#animationContainer-' + this.props.section.get('id') + '-' + this.props.slideIndex).find('.hoverAnimated');
    toBeAnimated.removeClass(this.props.section.getAnimationClass('hover'));
  }

  slideChanged(value){
    this.props.slideChanged(this.props.slideIndex, value);
  }

  slideClicked = () => {
    window.open(Helper.fixHref(this.props.slide.href), this.props.slide.target || '_self');
  };

  render() {
    let section = this.props.section;
    //Animations
    let hoverAnimation = section.getAnimation('hover');
    let loadAnimation = section.getAnimation('load');
    let hoverTarget = hoverAnimation ? hoverAnimation.target : '';
    let loadTarget = loadAnimation ? loadAnimation.target : '';
    let animateOnSlide = ' slideAnimated ';
    let animateOnHover = ' hoverAnimated ';
    let animateOnLoad = section.getAnimationClass('load');

    let shadowCSS = this.props.section.ls('text-shadow') ? this.props.section.ls('text-shadow').shadow_css : '';

    let backgroundUrl = null;
    if (this.props.slide.background_file_id) {
      let slide = this.props.slide;
      let background = FWFile.findOrCreate({id: this.props.slide.background_file_id});
      let size = this.props.size.width > this.props.size.height ? this.props.size.width : this.props.size.height;
      backgroundUrl = background.helper.thumbnailUrl(size);
      if (!slide.aspectRatioCalculated) {
        slide.aspectRatioCalculated = true;
        let img = new Image();
        const slider = this.props.slider;
        img.addEventListener('load', () => {
          let wideOrHigh = Helper.getWideOrHigh({
            height: img.naturalHeight,
            width: img.naturalWidth
          });
          slide.width = img.naturalWidth;
          slide.height = img.naturalHeight;
          let ratio = slide.width / slide.height;
          if (ratio < 1)
            ratio = 1 / ratio;
          if (ratio > slider.maxAspectRatio) {
            slider.maxAspectRatio = ratio;
            if (slider.slideWidth !== slide.width || slider.slideHeight !== slide.height) {
              slider.setSlideSize(slide.width, slide.height);
            }
          }
          this.setState({
            wideOrHigh: wideOrHigh
          });
        });
        img.src = backgroundUrl;
      }
    }

    let backgroundObj = backgroundUrl ? {backgroundImage: 'url(' + backgroundUrl + ')'} : {};

    let backgroundSize = section.prop('contain') ? 'contain' : 'cover';
    if (this.props.constrain) {
      let sliderWideOrHigh = Helper.getWideOrHigh(this.props.slider.props.size);
      if (this.state.wideOrHigh < sliderWideOrHigh) {
        backgroundSize = '100% auto';
      } else {
        backgroundSize = 'auto 100%';
      }
    }

    let slideContent = <div style={{position: 'absolute', marginLeft: '15%', width: '70%', maxWidth: '70%', display: 'table', height: '100%'}}>
      <div style={{position: 'relative', display: 'table-cell', verticalAlign: 'middle'}}
        className={(loadTarget === 'text' ? animateOnLoad : '') + (hoverTarget === 'text' ? animateOnHover : '')}>
        {window.innerWidth <= 600 && this.props.slide.hide_text_on_mobile ? null :<InlineEditableElement
          html={this.props.slide.content || ''}
          fontFamilyChanged={this.props.fontFamilyChanged}
          onChange={this.slideChanged}
          inlineEditingEnabled={this.props.inlineEditingEnabled && this.props.visible}
          customFontSizes
        />}
      </div>
    </div>;

    return <div
      id={'animationContainer-' + this.props.section.get('id') + '-' + this.props.slideIndex}
      style={{height: this.props.height, textAlign: 'center', overflow: 'hidden', display: 'inline-block', width: '100%', position: 'absolute'}}
      onMouseLeave={this.mouseLeave.bind(this)}
      onMouseOver={this.mouseOver.bind(this)}
      className={(loadTarget === 'whole' ? animateOnLoad : '')}
    >
      <div className={animateOnSlide + (hoverTarget === 'whole' ? animateOnHover : '')} style={{height: this.props.height}}>
        <div style={{
          width: '100%',
          height: this.props.height,
          backgroundSize: backgroundSize,
          backgroundPosition: 'center center',
          backgroundRepeat: 'no-repeat',
          ...backgroundObj
        }}
        className={(section.prop('parallax') !== false ? 'fw-parallax' : 'fw-no-parallax')} />
        <div className={'jumbotron ' + (loadTarget === 'overlay' ? animateOnLoad : '') + (hoverTarget === 'overlay' ? animateOnHover : '')}
             style={{
               position: 'absolute',
               top: 0,
               left: 0,
               width: '100%',
               height: this.props.height,
               backgroundColor: this.props.slide.background,
               padding: 0,
               paddingTop: '0',
               textShadow: shadowCSS
             }}>
          {this.props.slide.href && !FW.inEditor() ? <span onClick={this.slideClicked}>
            {slideContent}
          </span> : slideContent}
        </div>
      </div>
    </div>;
  }
}

export default CarouselSlide;
