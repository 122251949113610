// @flow
import {Backbone} from 'FWBackbone';
import _ from 'underscore';
import {Model} from './Model';
import {Collection} from './Collection';
import {PageSectionLayout} from './PageSectionLayout';
import {PageSectionLayoutSetting} from './PageSectionLayoutSetting';
import {PageSectionType} from './PageSectionType';
import Classes from '../../Page/Sections/index';
import {SizeMe} from 'react-sizeme';
import React, {useEffect, useRef, useState} from 'react';
import ErrorBoundary from 'ErrorBoundary';
import prop from './Decorators/Prop';
import layoutable from './Decorators/Layoutable';
import layoutSettings from './Decorators/LayoutSettings';
import hasBackground from './Decorators/HasBackground';
import updatesPage from './Decorators/UpdatesPage';
import {ChatMessage, ChatMessages} from './ChatMessage';
import browserizr, {isSafariVersion, MORE_THEN_OR_EQUAL, isIOS, isFirefox} from '@wezom/browserizr';

const getBodyScale = function() {
  const regExp = /scale\(([^)]+)\)/;
  let doc = window.name !== 'editFrame' ? (FW.editor ? FW.editor.getIframeWindow().document : document) : document;
  const bodyTransform = doc && doc.body ? doc.body.style.transform : '';
  const matches = regExp.exec(bodyTransform);
  return matches ? matches[1] : 1;
}

let isSafari17OrHigher = true;

const SizeMeWrapper = (props) => {
  const sectionWrapper = useRef();

  if (isSafari17OrHigher) {
    var [size, setSize] = useState({width: sectionWrapper.current ? sectionWrapper.current.offsetWidth : 0, height: sectionWrapper.current ? sectionWrapper.current.offsetHeight : 0});
    useEffect(() => {
      const resizeObserver = new ResizeObserver(entries => {
        const scale = getBodyScale();
        setSize({height: entries[0].target.offsetHeight * scale, width: entries[0].target.offsetWidth * scale});
      })
      // start observing a DOM node
      resizeObserver.observe(sectionWrapper.current)
    }, []);
  } else {
    var size = props.size;
  }

  useEffect(() => {
    props.finishedRendering(props.section, size);
  }, [size.height, size.width]);
  try {
    var cssText = props.section.prop('section_styles');
    var regex = /([\w-]*)\s*:\s*([^;]*)/g;
    var match, properties={};
    while(match=regex.exec(cssText)) properties[match[1]] = match[2].trim();
  } catch (e) {
    var properties = {};
  }
  properties.position = 'relative';

  return <div ref={sectionWrapper} style={properties}>
    <props.component {...props} size={size} />
    <div style={{width: '100%', clear: 'both', height: 0}} />
  </div>;
};


function sectionWrapper(Component) {
  return class SectionWrapper extends React.Component {
    state: {
      inlineEditingEnabled: boolean,
      props: {}
    };
    _heightTimeout: number;

    constructor(props) {
      super(...arguments);

      if (typeof Component === 'string') {
        let importCallback = (comp) => {
          Component = comp.default;
          this.forceUpdate();
        };
        if (Component === './SignUp/SignUp') {
          import('Page/Sections/SignUp/SignUp').then(importCallback);
        } else if (Component === './Scheduling/ResourceReservationSection') {
          import('Page/Sections/Scheduling/ResourceReservationSection').then(importCallback);
        } else if (Component === './Scheduling/ResourceGroupReservationSection') {
          import('Page/Sections/Scheduling/ResourceGroupReservationSection').then(importCallback);
        } else if (Component === './Form/Form') {
          import('Page/Sections/Form/Form').then(importCallback);
        } else if (Component === './MediaLibrary/MediaLibrary') {
          import('Page/Sections/MediaLibrary/MediaLibrary').then(importCallback);
        } else if (Component === './MediaLibrary/MediaLibraryList') {
          import('Page/Sections/MediaLibrary/MediaLibraryList').then(importCallback);
        } else if (Component === './MediaLibrary/MediaLibraryHorizontal') {
          import('Page/Sections/MediaLibrary/MediaLibraryHorizontal').then(importCallback);
        } else if (Component === './MediaLibrary/MediaLibrarySeries') {
          import('Page/Sections/MediaLibrary/MediaLibrarySeries').then(importCallback);
        } else if (Component === './MediaLibrary/MediaLibrarySmallBlock') {
          import('Page/Sections/MediaLibrary/MediaLibrarySmallBlock').then(importCallback);
        } else if (Component === './MediaLibrary/MediaLibraryHorizontal') {
          import('Page/Sections/MediaLibrary/MediaLibraryHorizontal').then(importCallback);
        } else if (Component === './Map/Map') {
          import('Page/Sections/Map/Map').then(importCallback);
        } else if (Component === './MembershipDirectory/MembershipDirectory') {
          import('Page/Sections/MembershipDirectory/MembershipDirectory').then(importCallback);
        } else if (Component === './Blog/Blog') {
          import('Page/Sections/Blog/Blog').then(importCallback);
        } else if (Component === './PayPal/PayPalGroup') {
          import('Page/Sections/PayPal/PayPalGroup').then(importCallback);
        } else if (Component === './PayPal/PayPalItem') {
          import('Page/Sections/PayPal/PayPalItem').then(importCallback);
        } else if (Component === './ExpandableText/ExpandableText') {
          import('Page/Sections/ExpandableText/ExpandableText').then(importCallback);
        } else if (Component === './IconBox/IconBox') {
          import('Page/Sections/IconBox/IconBox').then(importCallback);
        } else if (Component === './Tabs/Tabs') {
          import('Page/Sections/Tabs/Tabs').then(importCallback);
        } else if (Component === './EventCountdown/EventCountdown') {
          import('Page/Sections/EventCountdown/EventCountdown').then(importCallback);
        } else if (Component === './Chat/Chat') {
          import('Page/Sections/Chat/Chat').then(importCallback);
        } else if (Component === './Calendar/Calendar') {
          import('Page/Sections/Calendar/Calendar').then(importCallback);
        } else if (Component === './UpcomingEvents/UpcomingEvents') {
          import('Page/Sections/UpcomingEvents/UpcomingEvents').then(importCallback);
        } else if (Component === './UpcomingEvents/UpcomingEventsHorizontal') {
          import('Page/Sections/UpcomingEvents/UpcomingEventsHorizontal').then(importCallback);
        } else if (Component === './CommentWall/CommentWall') {
          import('Page/Sections/CommentWall/CommentWall').then(importCallback);
        } else if (Component === './AgreementPrompt/AgreementPrompt') {
          import('Page/Sections/AgreementPrompt/AgreementPrompt').then(importCallback);
        } else if (Component === './Dropdown/Dropdown') {
          import('Page/Sections/Dropdown/Dropdown').then(importCallback);
        } else if (Component === './MediaLibrary/MediaLibraryPlayer') {
          import('Page/Sections/MediaLibrary/MediaLibraryPlayer').then(importCallback);
        } else if (Component === './Video/Video') {
          import('Page/Sections/Video/Video').then(importCallback);
        } else if(Component === './PhotoGallery/GalleryIndex') {
          import('Page/Sections/PhotoGallery/GalleryIndex').then(importCallback);
        } else if (Component === './Scheduling/ResourceGroupCalendarSection') {
          import('Page/Sections/Scheduling/ResourceGroupCalendarSection').then(importCallback);
        } else if (Component === './Scheduling/ResourceGroupListSection') {
          import('Page/Sections/Scheduling/ResourceGroupListSection').then(importCallback);
        } else if (Component === './Scheduling/ResourceCalendarSection') {
          import('Page/Sections/Scheduling/ResourceCalendarSection').then(importCallback);
        } else if (Component === './Scheduling/ResourceListSection') {
          import('Page/Sections/Scheduling/ResourceListSection').then(importCallback);
        } else if (Component === './Tiles/Tiles') {
          import('Page/Sections/Tiles/Tiles').then(importCallback);
        } else if (Component === './SingleFile/SingleFile') {
          import('Page/Sections/SingleFile/SingleFile').then(importCallback);
        } else if (Component === './PhotoGallery/PhotoGallery') {
          import('Page/Sections/PhotoGallery/PhotoGallery').then(importCallback);
        } else if (Component === './RssFeed/RssMediaFeed') {
          import('Page/Sections/RssFeed/RssMediaFeed').then(importCallback);
        } else if (Component === './AME/Ame') {
          import('Page/Sections/AME/Ame').then(importCallback);
        } else if (Component === './FinalwebTemplates/FinalwebTemplates') {
          import('Page/Sections/FinalwebTemplates/FinalwebTemplates').then(importCallback);
        } else if(Component === './EventList/EventList') {
          import('Page/Sections/EventList/EventList').then(importCallback);
        } else if(Component === './Flipbook/Flipbook') {
          import('Page/Sections/Flipbook/Flipbook').then(importCallback);
        } else if(Component === './Faithlife/FaithlifeImport') {
          import('Page/Sections/Faithlife/FaithlifeImport').then(importCallback);
        } else if(Component === './NewsletterSignup/NewsletterSignup') {
          import('Page/Sections/NewsletterSignup/NewsletterSignup').then(importCallback);
        } else if(Component === './EmailDirections/EmailDirections') {
          import('Page/Sections/EmailDirections/EmailDirections').then(importCallback);
        } else if(Component === './ServantKeeper/Directory') {
          import('Page/Sections/ServantKeeper/Directory').then(importCallback);
        } else if(Component === './ServantKeeper/DonationButton') {
          import('Page/Sections/ServantKeeper/DonationButton').then(importCallback);
        } else if(Component === './ServantKeeper/MemberPortal') {
          import('Page/Sections/ServantKeeper/MemberPortal').then(importCallback);
        } else if(Component === './ServantKeeper/OnlineOffice') {
          import('Page/Sections/ServantKeeper/OnlineOffice').then(importCallback);
        } else if(Component === './VerseOfTheDay/VerseOfTheDay') {
          import('Page/Sections/VerseOfTheDay/VerseOfTheDay').then(importCallback);
        } else if(Component === './MediaLibrary/MediaLibraryCarousel') {
          import('Page/Sections/MediaLibrary/MediaLibraryCarousel').then(importCallback);
        } else if(Component === './VolunteerCounter/VolunteerCounter') {
          import('Page/Sections/VolunteerCounter/VolunteerCounter').then(importCallback);
        } else if(Component === './VolunteerSummary/VolunteerSummary') {
          import('Page/Sections/VolunteerSummary/VolunteerSummary').then(importCallback);
        } else if(Component === './ShareButton/ShareButton') {
          import('Page/Sections/ShareButton/ShareButton').then(importCallback);
        } else if (typeof Component === 'string') {
          console.warn('COMPONENT NOT CONFIGURED: ', Component);
        }
        Component = function(){return <div></div>;};
      }

      this.state = {
        props: props,
        inlineEditingEnabled: false
      };
    }

    componentDidMount() {
      this.props.section.on(
        'change',
        () => {
          this.forceUpdate();
        },
        this
      );
      this.props.section.on(
        'reboot',
        () => {
          let stateProps = this.state.props ? this.state.props : {};
          let props = _.clone({...stateProps, reboot: (stateProps.reboot ? stateProps.reboot + 1 : 1)});
          this.setState({
            props: props
          });
        },
        this
      );
      this.props.section.on(
        'start:inline',
        () => {
          this.setState({
            inlineEditingEnabled: true
          });
        },
        this
      );
      this.props.section.on(
        'stop:inline',
        () => {
          this.setState({
            inlineEditingEnabled: false
          });
        },
        this
      );
    }

    componentWillUnmount() {
      this.props.section.off(null, null, this);
    }

    componentDidUpdate() {
      /*
       * Using a timeout here because when the breakpoint changes in the editor,
       * the browser has to re-render the component before it knows the correct height
       * at the new breakpoint
       */
      /*clearTimeout(this._heightTimeout);
      this._heightTimeout = setTimeout(() => {
        if (this.props.finishedRendering) {
          this.props.finishedRendering(this.props.section, this.props.size);
        }
      }, 100);*/
    }

    UNSAFE_componentWillReceiveProps(newProps) {
      this.setState({
        props: newProps
      });
    }

    render() {
      const SWrapper = <SizeMeWrapper
        {...this.state.props}
        component={Component}
        key={this.state.props.section.get('id')}
        inlineEditingEnabled={this.state.inlineEditingEnabled} />;
      const Comp = isSafari17OrHigher ? SWrapper :
        <SizeMe monitorHeight>
          {(p) => { return <SizeMeWrapper
            {...this.state.props}
            component={Component}
            size={p.size}
            key={this.state.props.section.get('id')}
            inlineEditingEnabled={this.state.inlineEditingEnabled} />; }}
        </SizeMe>

      return (
        <ErrorBoundary>
          {Comp}
        </ErrorBoundary>
      );
    }
  };
}

@prop
@layoutable('section', 'group')
@layoutSettings('properties')
@hasBackground
@updatesPage
class PageSection extends Model {
  get modelUrl(): string {
    return '/page-sections';
  }

  get defaults(): {} {
    return {
      type: 'page_section'
    };
  }

  get syncRelations(): [] {
    return [
      //'page_section_properties',
      //'layout_settings'
    ];
  }

  getEditButtons(): {} {
    return _.union(this._editButtons, this._defaultEditButtons);
  }

  _editButtons = [];

  _defaultEditButtons = [
    {
      title: 'Move Section',
      class: 'glyphicon-move',
      type: 'btn-default',
      action: () => {
        window.parent.FW.editor.setMode('editSections', 'moveSection', {
          section: this
        });
      }
    },
    {
      title: 'Section Properties',
      class: 'glyphicons cogwheel',
      type: 'btn-default',
      action: () => {
        window.parent.FW.editor.setMode('editSections', 'editProperties', {
          section: this
        });
      }
    },
    {
      title: 'Duplicate Section',
      class: 'glyphicons duplicate',
      type: 'btn-default',
      action: () => {
        this.copy();
      }
    },
    {
      title: 'Remove Section',
      class: 'glyphicon-remove',
      type: 'btn-danger',
      action: () => {
        window.parent.FW.editor.setMode('editSections', 'removeSection', {
          section: this
        });
      }
    }
  ];

  getReactComponent() {
    if (!this._reactClass) {
      let template = FW.store.get('template'),
        typePath = this.get('section_type').get('path'),
        layoutPath = this.get('layout').get('path');
      let templateComponent =
          template._sectionClasses[typePath] &&
          template._sectionClasses[typePath][layoutPath]
            ? template._sectionClasses[typePath][layoutPath]
            : null,
        mainComponent = Classes[typePath]
          ? Classes[typePath][layoutPath]
          : null || Classes.default;
      //console.log(templateComponent, mainComponent);
      let Component = templateComponent || mainComponent;
      this._reactClass = sectionWrapper(Component);
    }
    return this._reactClass;
  }

  boot() {}

  shutdown() {}

  reboot() {
    this.shutdown();
    $.get(this.htmlUrl(), data => {
      FW.Models.PageSection.parseJsonData(data.data);
      this.trigger('reboot');
    });
  }

  htmlUrl() {
    return this.url().split('?')[0] + '/html';
  }

  static parseJsonData(data) {
    _.each(data, value => {
      try {
        let Col = FW.Models[value.model];
        if (!value.model) {
          FW.store.set(value.name, value.data);
        } else if (FW.Models.Model.isPrototypeOf(Col)) {
          FW.store.set(value.name, Col.findOrCreate(value.data));
        } else {
          FW.store.set(value.name, new Col(value.data));
        }
      } catch (e) {
        console.warn(e, value);
      }
    });
  }

  /**
   * Gets the DOM element for this section.
   * @returns {*|jQuery|HTMLElement}
   */
  element() {
    return $(
      "*[data-fw-model='PageSection'][data-id='" + this.get('id') + "']"
    );
  }

  /**
   * Gets the row this property belongs to.
   */
  group(): PageGroup {
    return this.get('group');
  }

  static makeSection(type, properties, layout) {
    layout = layout || 'standard';
    return new FW.Models.PageSection({
      properties: properties,
      section_type: new PageSectionType({
        path: type
      }),
      layout: new PageSectionLayout({
        path: layout
      })
    });
  }

  copy(group, page) {
    let currentSection = this;
    return new Promise(function(complete) {
      if (!currentSection.get('page_section_type_id')) {
        currentSection.fetch().then(() => {
          currentSection.copyLoadedSection(
            group,
            page,
            currentSection,
            complete
          );
        });
      } else currentSection.copyLoadedSection(group, page, currentSection, complete);
    });
  }

  copyLoadedSection(group, page, currentSection, complete) {
    let iframe = document.getElementById('editFrame')
      ? document.getElementById('editFrame').contentWindow
      : {};
    let realFW = FW;
    if (iframe.FW) {
      realFW = FW;
    }
    const isOnNewPage =
      page && page.get('id') !== realFW.store.get('page').get('id');
    group = group || currentSection.get('group');
    page = page || realFW.store.get('page');
    let oldProps = _.clone(currentSection.get('properties'));
    let section = FW.Models.PageSection.create({
      page_section_layout_id: currentSection.get('layout_settings')
        ? currentSection.get('layout_settings').get('page_section_layout_id')
        : null,
      page_section_type_id: currentSection.get('page_section_type_id'),
      page_group_id: group.get('id')
    });
    if (oldProps) {
      if (oldProps.grid_layouts && isOnNewPage) {
        oldProps.grid_layouts.lg.i = 'section-' + section.get('id');
        oldProps.grid_layouts.sm.i = 'section-' + section.get('id');
        oldProps.grid_layouts.xs.i = 'section-' + section.get('id');
      }
    } else oldProps = {};
    section.set('properties', oldProps);
    section.set('order_id', currentSection.get('order_id'));
    section.save(
      {},
      {
        silent: true, //needs to be silent so the page doesn't update until we parse the JSON data.
        success: () => {
          section.set('properties', oldProps || {});
          section
            .get('layout_settings')
            .set(
              'properties',
              currentSection.get('layout_settings').get('properties')
            );
          if (!isOnNewPage) {
            page.sections.add(section);
            $.get(section.htmlUrl(), data => {
              FW.Models.PageSection.parseJsonData(data.data);
              section.forceUpdate = true;
              group.get('sections').trigger('update'); //since we just parsed the JSON data, we can trigger the update so the page updates.
            });
          }
          section.get('layout_settings').save(
            {},
            {
              success: () => {
                complete();
              }
            }
          );
        }
      }
    );
  }

  getAnimation(trigger) {
    return this.ls('animations')
      ? _.findWhere(this.ls('animations'), {trigger: trigger})
      : null;
  }

  getAnimationType(animation, typeID) {
    return animation
      ? animation.types.length > 1 && typeID
        ? _.findWhere(animation.types, {id: typeID}).value
        : animation.types[0].value
      : '';
  }

  hasAnimations() {
    return !!this.ls('animations');
  }

  getAnimationTarget(trigger) {
    let animation = this.getAnimation(trigger);
    return animation ? animation.target : '';
  }

  getAnimationClass(trigger, typeID) {
    let animation = this.getAnimation(trigger);
    //If there are multiple types in the animation and a typeID was passed
    let animationType = this.getAnimationType(animation, typeID);
    return animationType
      ? 'animated ' +
          animationType +
          ' ' +
          (animation.delay || '') +
          ' ' +
          (animation.speed || '')
      : null;
  }
}
PageSection.prototype.relations = [
  {
    type: Backbone.Relational.HasOne,
    key: 'layout',
    relatedModel: PageSectionLayout
  },
  {
    type: Backbone.Relational.HasOne,
    key: 'layout_settings',
    relatedModel: PageSectionLayoutSetting
  },
  {
    type: Backbone.Relational.HasMany,
    key: 'section_layout_settings',
    relatedModel: PageSectionLayoutSetting,
    collectionOptions: Model.defaultCollectionOptions,
    reverseRelation: {
      key: 'section'
    }
  },
  {
    type: Backbone.Relational.HasMany,
    key: 'messages',
    relatedModel: ChatMessage,
    collectionType: ChatMessages,
    collectionOptions: Model.defaultCollectionOptions,
    reverseRelation: {
      key: 'section'
    }
  },
  {
    type: Backbone.Relational.HasOne,
    key: 'section_type',
    relatedModel: PageSectionType
  }
];

class PageSections extends Collection {
  get comparator(): Function {
    return (s1: PageSection, s2: PageSection): number => {
      let l1 = s1.getLayout('lg');
      let l2 = s2.getLayout('lg');
      if (l1.y < l2.y) {
        return -1;
      } else {
        if (l1.y === l2.y && l1.x <= l2.x) {
          return -1;
        }
        return 1;
      }
    };
  }
}
PageSections.prototype.model = PageSection;

export {PageSection, PageSections};
