import Image from './Image/Image';
import PlainText from './PlainText/PlainText';
import Html from './Html/Html';
import Heading from './Heading/Heading';
import BlogPreview from './BlogPreview/BlogPreview';
import BlogPreviewList from './BlogPreview/BlogPreviewList';
import Jumbotron from './Jumbotron/Jumbotron';
import Audio from './Audio/Audio';
import JumbotronCarousel from './JumbotronCarousel/JumbotronCarousel';
import RssFeed from './RssFeed/RssFeed';
import SocialMedia from './SocialMedia/SocialMedia';
import FeatureBox from './FeatureBox/FeatureBox';
import Donate from './Donate/Donate';
import HitCounter from './HitCounter/HitCounter';
import Menu from './Menu/Menu';
import Trial from './Trial/Trial';
import BlogPreviewNews from './BlogPreview/BlogPreviewNews';

export default {
  'media-library': {
    block: './MediaLibrary/MediaLibrary',
    list: './MediaLibrary/MediaLibraryList',
    series: './MediaLibrary/MediaLibrarySeries',
    horizontal: './MediaLibrary/MediaLibraryHorizontal',
    'small-block': './MediaLibrary/MediaLibrarySmallBlock',
    'player': './MediaLibrary/MediaLibraryPlayer',
    'carousel': './MediaLibrary/MediaLibraryCarousel'
  },
  heading: {
    standard: Heading
  },
  video: {
    standard: './Video/Video'
  },
  blog: {
    standard: './Blog/Blog'
  },
  'blog-preview': {
    block: BlogPreview,
    list: BlogPreviewList,
    news: BlogPreviewNews
  },
  jumbotron: {
    standard: Jumbotron
  },
  image: {
    standard: Image
  },
  'plain-text': {
    standard: PlainText
  },
  html: {
    standard: Html
  },
  audio: {
    standard: Audio
  },
  calendar: {
    standard: './Calendar/Calendar'
  },
  'jumbotron-carousel': {
    standard: JumbotronCarousel
  },
  map: {
    standard: './Map/Map'
  },
  'photo-gallery': {
    standard: './PhotoGallery/PhotoGallery'
  },
  'rss-feed': {
    standard: RssFeed,
    media: './RssFeed/RssMediaFeed'
  },
  form: {
    standard: './Form/Form'
  },
  'sign-up': {
    standard: './SignUp/SignUp'
  },
  'social-media': {
    standard: SocialMedia
  },
  'feature-box': {
    standard: FeatureBox
  },
  'single-file': {
    standard: './SingleFile/SingleFile'
  },
  tiles: {
    standard: './Tiles/Tiles'
  },
  tabs: {
    standard: './Tabs/Tabs'
  },
  trial: {
    standard: Trial
  },
  donate: {
    standard: Donate
  },
  countdown: {
    standard: './EventCountdown/EventCountdown'
  },
  'upcoming-events': {
    standard: './UpcomingEvents/UpcomingEvents',
    horizontal: './UpcomingEvents/UpcomingEventsHorizontal'
  },
  'expandable-text': {
    standard: './ExpandableText/ExpandableText'
  },
  'paypal-group': {
    standard: './PayPal/PayPalGroup'
  },
  'paypal-item': {
    standard: './PayPal/PayPalItem'
  },
  'membership-directory': {
    standard: './MembershipDirectory/MembershipDirectory',
    list: './MembershipDirectory/MembershipDirectory'
  },
  'hit-counter': {
    standard: HitCounter
  },
  menu: {
    standard: Menu
  },
  'scheduling-group': {
    calendar: './Scheduling/ResourceGroupCalendarSection',
    list: './Scheduling/ResourceGroupListSection'
  },
  'scheduling-resource': {
    calendar: './Scheduling/ResourceCalendarSection',
    list: './Scheduling/ResourceListSection'
  },
  'scheduling-group-form': {
    standard: './Scheduling/ResourceGroupReservationSection'
  },
  'scheduling-resource-form': {
    standard: './Scheduling/ResourceReservationSection'
  },
  'icon-box': {
    standard: './IconBox/IconBox'
  },
  'image-gallery-index': {
    standard: './PhotoGallery/GalleryIndex'
  },
  'chat': {
    standard: './Chat/Chat'
  },
  'comment-wall': {
    standard: './CommentWall/CommentWall'
  },
  'agreement-prompt': {
    standard: './AgreementPrompt/AgreementPrompt'
  },
  'dropdown': {
    standard: './Dropdown/Dropdown'
  },
  'ame': {
    standard: './AME/Ame'
  },
  'finalweb-templates': {
    standard: './FinalwebTemplates/FinalwebTemplates'
  },
  'event-list': {
    standard: './EventList/EventList'
  },
  'flip-book': {
    standard: './Flipbook/Flipbook'
  },
  'faithlife-import': {
    standard: './Faithlife/FaithlifeImport'
  },
  'newsletter-signup': {
    standard: './NewsletterSignup/NewsletterSignup'
  },
  'email-directions': {
    standard: './EmailDirections/EmailDirections'
  },
  'servant-keeper': {
    'online-office': './ServantKeeper/OnlineOffice',
    'member-portal': './ServantKeeper/MemberPortal',
    'directory': './ServantKeeper/Directory',
    'donation-button': './ServantKeeper/DonationButton'
  },
  'verse-of-the-day': {
    standard: './VerseOfTheDay/VerseOfTheDay'
  },
  'volunteer-counter': {
    standard: './VolunteerCounter/VolunteerCounter'
  },
  'volunteer-summary': {
    standard: './VolunteerSummary/VolunteerSummary'
  },
  'share-button': {
    standard: './ShareButton/ShareButton'
  }
};
